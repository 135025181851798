import React, { Suspense } from 'react';
import { Alert, CircularProgress, CssBaseline, Grid, LinearProgress, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {
  RouterProvider, createHashRouter,
  createRoutesFromElements, defer,
  Route, useOutlet,
  useLoaderData, Await, Navigate, useLocation
} from "react-router-dom";

import Dashboard from "./scenes/admin/dashboard_stunting";
import Menu from './scenes/admin/global/Menu';
import Login from './scenes/login';
import Home from './scenes/home';
import Anakterdaftar from './scenes/admin/anakterdaftar';
import MapStunting from './scenes/admin/map_stunting';
import MintervensiStunting from './scenes/admin/mintervensi';
import IntervensiStunting from './scenes/admin/intervensi_stunting';
import IntervensiKemiskinan from './scenes/admin/intervensi_kemiskinan';
import MiskinEkstrim from './scenes/admin/miskinekstrim';
import MapKemiskinan from './scenes/admin/map_kemiskinan';
import { AuthProvider, useAuth } from './auth/auth_provider';
import DaftarUser from './scenes/admin/akun';
import MintervensiKemiskinan from './scenes/admin/mintervensi_kemiskinan';
import { Box } from '@mui/system';



const App = () => {
  const [theme, colorMode] = useMode()
  // const { user } = useAuth();
  const router =
    createHashRouter(
      createRoutesFromElements(
        <Route path='/' element={<AuthLayout />}
          loader={() => defer({ userPromise: getUserData() })}
        >
          <Route path="login" element={<Login />} />
          <Route index element={<Home />} />
          <Route path="admin" element={<ProtectedLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="mapstunting" element={<MapStunting />} />
            <Route path="mapkemiskinan" element={<MapKemiskinan />} />
            <Route path="intervensi" element={<IntervensiStunting />} />
            <Route path="interkemiskinan" element={<IntervensiKemiskinan />} />
            <Route element={<ProtectedAdminLayout />}>
              <Route path="daftaruser" element={<DaftarUser />} />
            </Route>
            <Route element={<ProtectedUserLayout />}>
              <Route path="miskinekstrim" element={<MiskinEkstrim />} />
              <Route path="anakterdaftar" element={<Anakterdaftar />} />
              <Route path="mintervensi" element={<MintervensiStunting />} />
              <Route path="minterkemiskinan" element={<MintervensiKemiskinan />} />
            </Route>
          </Route>
        </Route>
      )
    );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

const getUserData = () =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
      reject("Error");
    }, 3000)
  );

const titles = {
  'main': 'Karebosi Apps',
  '/admin/dashboard': 'Dashboard',
}

const ProtectedLayout = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Menu />
    </div>
  );
};



const ProtectedUserLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user.type === 'user') {
    return <Alert severity="error">Something went wrong!</Alert>;
  }
  return (<div>{outlet}</div>)
};

const ProtectedAdminLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user.type === 'admin' || user.type === 'user') {
    return <Alert severity="error">Something went wrong!</Alert>;
  }

  return (<div>{outlet}</div>)
};

const AuthLayout = () => {
  const oulet = useOutlet();
  const { userPromise } = useLoaderData();

  return (
    <Suspense fallback={
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        bgcolor: 'Highlight',
        height: '100vh'
      }}>
        <Box sx={{ height: '100vh'}}>
          <CircularProgress />
        </Box>
      </Box>
    }>
      <Await
        resolve={userPromise}
        errorElement={<Alert severity="error">Something went wrong!</Alert>}
        children={
          (user) => (
            <AuthProvider userData={user}>
              {oulet}
            </AuthProvider>
          )
        }

      />
    </Suspense>
  );
}


export default App;
