import React from "react";
import { useState, useEffect, useCallback } from "react";
import { tokens } from "../../../theme";
import { headerData } from "../../../data/headerCostum";
import { useAuth } from "../../../auth/auth_provider";
import ShowAlert from "../global/alert";
import {
    useTheme,
    Button,
    Typography,
    Fade,
    Grid,
    Modal,
    Divider,
    Autocomplete,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";



const InputIntervensi = ({ id, execute }) => {
    //Basic Theme
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); getDataAnak() }
    const hadleAlertModal = () => setOpenModal(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    //Authenticate
    const { user } = useAuth();

    //State variable
    const [dataAnak, setDataAnak] = useState([]);
    const [dataMintervensi, setDataMintervensi] = useState([])

    const [idMintervensi, setIdMintervensi] = useState(null)
    const [inputlabelMintervensi, setLabelMintervensi] = useState('')
    const [inputKuantitas, setInputKuantitas] = useState(1)
    const [inputUnit, setInputUnit] = useState([]);
    const [inpuUnitValue, setInputunitvalue] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [alertOption, setAlertOption] = useState({
        title: '',
        desc: '',
        type: 'info'
    });

    //get Data Anak
    const getDataAnak = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/anak/" + id, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setDataAnak(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [setDataAnak, id])

    //getData Mintervensi
    const getDataMintervensi = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/mintervensi", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setDataMintervensi(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [setDataMintervensi])

    const hanldeInputUnit = (v) => {
        if(v !== null){
            const unitArray = v.unit.replace('[', '').replace(']', '').split(' ').join('_').split(',')
            setInputUnit(unitArray)
            setInputunitvalue('')
        }
    }

    const handleInputUnitvalue = (e) => {
        setInputunitvalue(e.target.value)
    }

    //Send data intervensi
    const sendIntervensiData = useCallback(() => {
        if (idMintervensi === null) {
            setCheckError(true);
            return;
        }
        fetch(process.env.REACT_APP_API_URL + "api/intervensi", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify({
                'userid': id,
                'dokumentasi': 'null',
                'intervensi': idMintervensi['id'],
                'opd_pendamping': user.opd,
                'kuantitas': inputKuantitas,
                'unit': inpuUnitValue
            })
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                execute();
                setAlertOption(
                    {
                        title: 'Sukses',
                        desc: 'Data Telah Disimpan !',
                        type: 'success'
                    }
                );
                setOpenAlert(true);
                setTimeout(hadleAlert, 4000);
                hadleAlertModal();
            })
            .catch(err => {
                console.log(err)
            })
    }, [id, execute, idMintervensi, user, inputKuantitas, inpuUnitValue])

    const hadleAlert = () => {
        setOpenAlert(false);
    };

    // Automatically close the alert after 3000 milliseconds (3 seconds)
    // setTimeout(hadleAlert, 5000);

    //Initiation Load Call
    useEffect(() => {
        if (dataAnak.length === 0) {
            getDataAnak()
        }
        if (dataMintervensi.length === 0) {
            getDataMintervensi();
        }
    }, [dataAnak, getDataAnak,
        dataMintervensi, getDataMintervensi,])

    return (
        <div>
            <ShowAlert
                title={alertOption.title}
                desc={alertOption.desc}
                type={alertOption.type}
                openAlert={openAlert}
                onAlertClose={hadleAlert}
            />
            <Button variant='contained' sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ":hover": {
                    backgroundColor: colors.greenAccent[800]
                },
                margin: '2px'
            }}
                onClick={handleOpenModal}
            >
                <Typography fontSize={9}>
                    Input
                </Typography>
            </Button>
            <Modal
                open={openModal}
                onClose={hadleAlertModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid container item xs={11} md={7} lg={5} sx={style}>
                        <Grid container item justifyContent={"space-between"} alignItems={"end"}>
                            <Typography variant="h4">
                                Input Data Intervensi
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={hadleAlertModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid container item m={1}>
                            <Grid item xs={12}>
                                <Typography marginY={1}>
                                    Nama          : {dataAnak.nama}
                                </Typography>
                                <Typography marginY={1}>
                                    Jenis Kelamin : {dataAnak.jenis_kelamin}
                                </Typography>
                                <Typography marginY={1}>
                                    Tanggal Lahir: {dataAnak.tgl_lahir}
                                </Typography>
                                <Typography marginY={1}>
                                    Berat Badan   : {dataAnak.BB_U}
                                </Typography>
                                <Typography marginY={1}>
                                    Tinggi Badan  : {dataAnak.TB_U}
                                </Typography>
                                <Typography marginY={1}>
                                    BB / TB       : {dataAnak.BB_TB}
                                </Typography>
                                <Autocomplete
                                    value={idMintervensi}
                                    onChange={(event, newValue) => {
                                        setIdMintervensi(newValue);
                                        setCheckError(false);
                                        hanldeInputUnit(newValue)
                                    }}
                                    inputValue={inputlabelMintervensi}
                                    onInputChange={(event, newInputValue) => {
                                        setLabelMintervensi(newInputValue);
                                    }}
                                    id="input-intervensi"
                                    options={dataMintervensi}
                                    groupBy={(option) => option.pengendalian}
                                    renderGroup={(option) =>
                                        <li key={option.key}>
                                            <Box
                                                sx={{
                                                    position: 'sticky',
                                                    top: '-8px',
                                                    padding: '4px 10px',
                                                    backgroundColor: colors.greenAccent[400],
                                                    color: colors.grey[800],
                                                    fontWeight: 'bold',
                                                }}
                                            >{option.group}</Box>
                                            {option.children}
                                        </li>
                                    }
                                    getOptionLabel={(option) => option.program}
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        marginTop: '20px'
                                    }}
                                    renderInput={(params) => <TextField error={checkError} size={'small'} {...params} label="Program Intervensi" />}
                                />
                                <TextField
                                    type="number"
                                    value={inputKuantitas}
                                    sx={{
                                        width: "100%",
                                        marginTop: 2,
                                    }}
                                    onInput={(e) => setInputKuantitas(e.target.value)}
                                    size="small" label="Kuantitas" />
                                <FormControl fullWidth sx={{
                                    marginY: 2
                                }}>
                                    <InputLabel size="small" id="select-unit-value">Unit</InputLabel>
                                    <Select
                                        labelId="select-unit-value"
                                        id="select-unit-value"
                                        value={inpuUnitValue}
                                        label="Age"
                                        size="small"
                                        onChange={handleInputUnitvalue}
                                    >
                                        {

                                            inputUnit.length > 0
                                                ? inputUnit.map((items) => {
                                                    return <MenuItem value={items}>{items}</MenuItem>
                                                })
                                                : ''
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} m={1} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colors.greenAccent[500]
                                }}
                                onClick={() => sendIntervensiData()}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    )
}

export default InputIntervensi;