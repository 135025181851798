import React from "react";
import Imac from '../../assets/image/imac.png';
import BG1 from '../../assets/image/BG.png'
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";


const styles = {
  paperContainer: {
    backgroundImage: `url(${BG1})`
  }
};

const Header = () => {

  return (
    <Grid item xs={12} sx={{
      backgroundImage: `url(${BG1})`,
      backgroundSize: 'cover',
      height: '100vh'
    }}>
      <Grid container item>
        <Grid xs={12} md={7} container item justifyContent={'end'}>
          <Box
            margin={10}
            component={'img'}
            alt="Intervensi"
            src={Imac}
            sx={{
              width: '70%',
            }}
          />
        </Grid>
        <Grid xs={12} md={5} container item sx={{
          alignItems: 'center',
          paddingX: 5
        }}>
          <Grid item>
            <Typography variant="h1" fontWeight={700}>
              Intervensi Stunting & Kemiskinan Secara Langsung.
            </Typography>
            <Grid sx={{
              marginTop: 2
            }}>
              <Link to='login'>
                <Button variant="contained"
                  sx={{
                    textTransform: 'capitalize',
                    marginY: 1,
                    marginX: 1,
                    backgroundColor: "#243752",
                    ":hover": {
                      backgroundColor: "ButtonHighlight",
                      color: "#243752"
                    },
                    fontSize: 14,
                    fontWeight: 500,
                  }}>Cek Data Stunting</Button>
              </Link>
              <Button
              variant="contained"
              href="https://play.google.com/store/apps/details?id=com.diskominfo.karebosi_mobile_apps"
              sx={{
                textTransform: 'capitalize',
                marginY: 1,
                marginX: 1,
                fontSize: 14,
                fontWeight: 500,
                backgroundColor: "#243752",
                ":hover": {
                  backgroundColor: "ButtonHighlight",
                  color: "#243752"
                },
              }}>Download Aplikasi</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;