import { Box, Grid, Typography, useTheme, CardMedia } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import WcIcon from '@mui/icons-material/Wc';
import WomanIcon from '@mui/icons-material/Woman';
import ManIcon from '@mui/icons-material/Man';
import { tokens } from '../../../theme';
import { headerData } from "../../../data/headerCostum";
import { zscoreGiziCalc } from "../../../data/zscoreCalc";
import {
    ComposedChart,
    XAxis,
    YAxis,
    Tooltip,
    Area,
    Bar,
    Line,
    ResponsiveContainer,
} from "recharts";



const iconSize = 40;
const textSize = 11;


const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataAnak, setDataAnak] = useState([]);
    const [dataMiskin, setDataMiskin] = useState([]);
    const [jumlahMiskin, setJumlahMiskin] = useState({
        all: 0,
        l: 0,
        p: 0
    });
    const [jumlahAnak, setJumlahAnak] = useState({
        all: 0,
        l: 0,
        p: 0
    });

    const [dataGizi, setDataGizi] = useState(
        [
            {
                name: 'Gizi Buruk',
                jumlah: 0,
            },
            {
                name: 'Gizi Kurang',
                jumlah: 0,
            },

            {
                name: 'Gizi Baik',
                jumlah: 0,
            },
            {
                name: 'Risiko Gizi Lebih',
                jumlah: 0,
            },
            {
                name: 'Gizi Lebih',
                jumlah: 0,
            },
            {
                name: 'Obesitas',
                jumlah: 0,
            }
        ]
    )

    const [dataSkalaMiskin, setDataSkalaMiskin] = useState(
        [
            {
                name: 'Ekstrim',
                jumlah: 0,
            },
            {
                name: 'Sedang',
                jumlah: 0,
            },

            {
                name: 'Rendah',
                jumlah: 0,
            },
        ]
    )


    const getDataDashboardAnak = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/anak", {
            method: "get",
            headers: headerData
        })
            .then(res => res.json())
            .then(data => setDataAnak(data))
            .catch(err => console.log(err))
    }, []);

    const getDataDashboardMiskin = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/miskin", {
            method: "get",
            headers: headerData
        })
            .then(res => res.json())
            .then(data => setDataMiskin(data))
            .catch(err => console.log(err))
    }, []);

    const calculateDataAnak = useCallback(() => {
        let jumlahAL = 0;
        let jumlahAP = 0;
        let totalAnak = dataAnak.length;
        dataAnak.map((items) => {
            if (items.jenis_kelamin === 'L') {
                return jumlahAL += 1;
            } else {
                return jumlahAP += 1;
            }
        })
        setJumlahAnak({
            all: totalAnak,
            l: jumlahAL,
            p: jumlahAP
        });
    }, [dataAnak]);

    const calculateDataMiskin = useCallback(() => {
        let jumlahAL = 0;
        let jumlahAP = 0;
        let totalMiskin = dataMiskin.length;
        dataMiskin.map((items) => {
            if (items.jenis_kelamin === 'Perempuan') {
                return jumlahAP += 1;
            } else {
                return jumlahAL += 1;
            }
        })
        setJumlahMiskin({
            all: totalMiskin,
            l: jumlahAL,
            p: jumlahAP
        });
    }, [dataMiskin]);

    const calculateDataSkalaMiskin = useCallback(() => {
        let ekstrim = 0;
        let sedang = 0;
        let rendah = 0;
        dataMiskin.forEach((items) => {
            if (parseFloat(items.penghasilan) <= 18000) {
                ekstrim += 1;
            } else if (parseFloat(items.penghasilan) > 18000 && parseFloat(items.penghasilan) <= 50000) {
                sedang += 1;
            } else if (parseFloat(items.penghasilan) > 50000) {
                rendah += 1
            }
        })

        let dataSkala =
            [
                {
                    name: 'Ekstrim',
                    jumlah: ekstrim,
                },
                {
                    name: 'Sedang',
                    jumlah: sedang,
                },

                {
                    name: 'Rendah',
                    jumlah: rendah,
                },
            ]
        setDataSkalaMiskin(dataSkala)
    }, [setDataSkalaMiskin, dataMiskin])

    const calculateDataGizi = useCallback(() => {
        let giziBuruk = 0;
        let giziBaik = 0;
        let giziKurang = 0;
        let resikoGiziLebih = 0;
        let giziLebih = 0;
        let obesitas = 0;
        dataAnak.forEach((items) => {
            if (zscoreGiziCalc(items['ZS_BB_TB']) === 'buruk') {
                giziBuruk += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'kurang') {
                giziKurang += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'normal') {
                giziBaik += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'resiko_lebih') {
                resikoGiziLebih += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'lebih') {
                giziLebih += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'obesitas') {
                obesitas += 1;
            }
        })
        let dataStatusStunting = [
            {
                name: 'Gizi Buruk',
                jumlah: giziBuruk,
            },
            {
                name: 'Gizi Kurang',
                jumlah: giziKurang,
            },

            {
                name: 'Gizi Baik',
                jumlah: giziBaik,
            },
            {
                name: 'Risiko Gizi Lebih',
                jumlah: resikoGiziLebih,
            },
            {
                name: 'Gizi Lebih',
                jumlah: giziLebih,
            },
            {
                name: 'Obesitas',
                jumlah: obesitas,
            }
        ]
        setDataGizi(dataStatusStunting)
    }, [dataAnak])

    useEffect(() => {
        if (dataAnak.length === 0) {
            getDataDashboardAnak()
        } else if (jumlahAnak.all === 0) {
            calculateDataAnak()
        } else if (dataGizi[0].jumlah === 0) {
            calculateDataGizi()
        }

        if (dataMiskin.length === 0) {
            getDataDashboardMiskin()
        } else if (jumlahMiskin.all === 0) {
            calculateDataMiskin()
        } else if (dataSkalaMiskin[0].jumlah === 0){
            calculateDataSkalaMiskin()
        }

    }, [dataAnak,
        getDataDashboardAnak,
        calculateDataAnak,
        calculateDataGizi,
        dataGizi,
        jumlahAnak.all,
        getDataDashboardMiskin,
        calculateDataMiskin,
        dataMiskin,
        jumlahMiskin,
    ])
    return (
        <Grid container xs={12}>
            <Grid container item xs={12} md={6}>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={6} paddingX={0.5}>
                        <Box sx={{
                            padding: "20px",
                            marginBottom: "5px",
                            border: 1,
                            borderRadius: 3,
                            width: "100%",
                        }}>
                            <Box>
                                <WcIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                            </Box>
                            <Box>
                                <Typography fontSize={textSize} color={colors.blueAccent[400]}>
                                    Jumlah Anak Terdaftar
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h1">
                                    {jumlahAnak.all}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={6} paddingX={0.5}>
                        <Grid item xs={6}>
                            <Box sx={{
                                border: 1,
                                borderRadius: 3,
                                marginBottom: "5px",
                                marginX: "3px",
                                padding: "20px"
                            }}>
                                <Box>
                                    <ManIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.blueAccent[400]}>
                                        Anak Laki-Laki
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h1">
                                        {jumlahAnak.l}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                border: 1,
                                borderRadius: 3,
                                marginBottom: "5px",
                                marginX: "3px",
                                padding: "20px"
                            }}>
                                <Box>
                                    <WomanIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.blueAccent[400]}>
                                        Anak Perempuan
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h1">
                                        {jumlahAnak.p}
                                    </Typography>
                                </Box>
                                {/* <Typography color={colors.greenAccent[300]}>
                                +5%
                            </Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} width={12}>
                    <Box sx={{
                        padding: "20px",
                        marginX: "5px",
                        marginBottom: "10px",
                        border: 1,
                        borderRadius: 3,
                        // width: "100%",
                    }}>
                        <Typography>Kondisi Anak Berdasarkan Skala Stunting</Typography>
                        <Box height="300px" sx={{
                            paddingTop: "1em",
                            paddingX: "1em"
                        }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    layout="vertical"
                                    width={500}
                                    height={400}
                                    data={dataGizi}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                >
                                    {/* <CartesianGrid stroke={colors.grey[700]} /> */}
                                    <XAxis dataKey="jumlah" type="number" />
                                    <YAxis
                                        dataKey="name"
                                        type="category"
                                        fontWeight={"bold"}
                                    />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                                    <Bar radius={5} background dataKey="jumlah" type="number" barSize={10} fill={colors.greenAccent[600]} />
                                    {/* <Line dataKey="jumlah" type="category" stroke="#ff7300" /> */}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={6} paddingX={0.5}>
                        <Box sx={{
                            padding: "20px",
                            marginBottom: "5px",
                            border: 1,
                            borderRadius: 3,
                            width: "100%",
                        }}>
                            <Box>
                                <WcIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                            </Box>
                            <Box>
                                <Typography fontSize={textSize} color={colors.blueAccent[400]}>
                                    Jumlah Kepala Keluarga Miskin Ekstrim
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h1">
                                    {jumlahMiskin.all}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={6} paddingX={0.5}>
                        <Grid item xs={6}>
                            <Box sx={{
                                border: 1,
                                borderRadius: 3,
                                marginBottom: "5px",
                                marginX: "3px",
                                padding: "20px"
                            }}>
                                <Box>
                                    <ManIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.blueAccent[400]}>
                                        Laki-Laki
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h1">
                                        {jumlahMiskin.l}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                border: 1,
                                borderRadius: 3,
                                marginBottom: "5px",
                                marginX: "3px",
                                padding: "20px"
                            }}>
                                <Box>
                                    <WomanIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.blueAccent[400]}>
                                        Perempuan
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h1">
                                        {jumlahMiskin.p}
                                    </Typography>
                                </Box>
                                {/* <Typography color={colors.greenAccent[300]}>
                                +5%
                            </Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} width={12}>
                    <Box sx={{
                        padding: "20px",
                        marginX: "5px",
                        marginBottom: "10px",
                        border: 1,
                        borderRadius: 3,
                        // width: "100%",
                    }}>
                        <Typography>Skala Kemiskinan</Typography>
                        <Box height="300px" sx={{
                            paddingTop: "1em",
                            paddingX: "1em"
                        }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    layout="vertical"
                                    width={500}
                                    height={400}
                                    data={dataSkalaMiskin}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                >
                                    {/* <CartesianGrid stroke={colors.grey[700]} /> */}
                                    <XAxis dataKey="jumlah" type="number" />
                                    <YAxis
                                        dataKey="name"
                                        type="category"
                                        fontWeight={"bold"}
                                    />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                                    <Bar radius={5} background dataKey="jumlah" type="number" barSize={10} fill={colors.greenAccent[600]} />
                                    {/* <Line dataKey="jumlah" type="category" stroke="#ff7300" /> */}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>

                </Grid>
            </Grid>
            <CardMedia
                    src="https://karebosi.makassarkota.go.id/mapstore/dashboard-embedded.html#/55"
                    component={'iframe'}
                    sx={{
                        height: '86vh'
                    }}
                />
        </Grid>
    );
}

export default Dashboard;