import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
// import { tokens } from '../../../theme';
import TambahMintervensi from './tambah';
import EditDataAnak from './edit';
import DeleteIntervensi from "./delete";
import { headerData } from '../../../data/headerCostum';

const columns = [
    {
        id: 'nama_skpd',
        label: 'Nama SKPD',
        minWidth: 200
    },
    {
        id: 'skpd_akronim',
        label: 'SKPD Akronim',
        minWidth: 100,
    },
    {
        id: 'u',
        label: 'U',
        minWidth: 50,
    },
    {
        id: 'bu',
        label: 'BU',
        minWidth: 50,
    },
    {
        id: 'p',
        label: 'P',
        minWidth: 50,
    },
    {
        id: 'k',
        label: 'K',
        minWidth: 50,
    },
    {
        id: 'sk',
        label: 'SK',
        minWidth: 50,
    },
    {
        id: 'p',
        label: 'P',
        minWidth: 50,
    },
    {
        id: 'sektor_urusan',
        label: 'Sektor Urusan',
        minWidth: 300,
    },
    {
        id: 'program',
        label: 'Program',
        minWidth: 300,
    },
    {
        id: 'kegiatan',
        label: 'Kegiatan',
        minWidth: 300,
    },
    {
        id: 'sub_kegiatan',
        label: 'Sub Kegiatan',
        minWidth: 300,
    },
    {
        id: 'indikator',
        label: 'Indikator',
        minWidth: 300,
    },
    {
        id: 'ta_2022',
        label: 'TA 2022',
        minWidth: 150,
        format: (value) => rupiah(value)
    },
    {
        id: 'ta_2023',
        label: 'TA 2023',
        minWidth: 150,
    },
    {
        id: 'ta_2024',
        label: 'TA 2024',
        minWidth: 150,
    },
    {
        id: 'lokasi_prioritas',
        label: 'Lokasi Prioritas',
        minWidth: 200,
    },
    {
        id: 'sumber_pendapatan',
        label: 'Sumber Pendapatan',
        minWidth: 200,
    },
    {
        id: 'keterangan',
        label: 'Keterangan',
        minWidth: 300,
    },
];

const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
    }).format(number);
}


export default function MintervensiKemiskinan() {
    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    let rowNumber = 0;
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [copyList, setCopyList] = useState([]);

    const getDataMintervensi = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/minterkemiskinan", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(d => {
                setRows(d)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const searchText = (searched) => {
        setCopyList(rows.filter((item) =>
            (isNaN(+searched))
                ? item.opd.toUpperCase().includes(searched.toUpperCase())
                : item.intervensi.toUpperCase().includes(searched.toUpperCase())
        ))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (rows.length === 0) {
            getDataMintervensi()
        } else {
            setCopyList(rows)
        }
    },
        [getDataMintervensi, rows]
    )

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Grid container xs={12} m={1} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={6} md={5} lg={3}>
                    <TextField
                        id="outlined-textarea"
                        label="Cari"
                        placeholder="Intervensi atau OPD"
                        multiline
                        sx={{
                            width: '100%'
                        }}
                        size='small'
                        onChange={(e) => searchText(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={6} md={5} lg={3} paddingRight={'4vw'} sx={{
                    // backgroundColor: colors.blueAccent[100]
                }}
                    justifyContent={'end'}
                >
                    <TambahMintervensi execute={getDataMintervensi} />
                </Grid>
            </Grid>
            <TableContainer sx={{ maxHeight: '90vh' }}>
                <Table size='small' stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'no'}
                                align={'center'}
                                style={{ minWidth: 5 }}
                            >
                                {'No'}
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell
                                key={'aksi'}
                                align={'center'}
                                style={{ minWidth: 10 }}
                            >
                                {'Aksi'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {copyList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                rowNumber += 1;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        <TableCell
                                            align='center'
                                            key={'no'}>
                                            {rowNumber + page * rowsPerPage}
                                        </TableCell>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell
                                            align='center'
                                            key={'aksi'}>
                                            <EditDataAnak id={row.id} execute={getDataMintervensi} />
                                            <DeleteIntervensi id={row.id} execute={getDataMintervensi} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={copyList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}