import { Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import BG1 from '../../assets/image/fitur.jpg';
import TableImg from '../../assets/image/datatable.png';
import { Box } from "@mui/system";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";

const Features = () => {
  return (
    <Grid container sx={{
      backgroundImage: `url(${BG1})`,
      backgroundSize: 'cover',
      height: '100%',
      justifyContent: 'center',
    }}>
      <Grid item xs={12} sx={{
        marginY: 3
      }}>
        <Typography variant="h2" align="center" fontWeight={600}>
          Fitur Utama
        </Typography>
      </Grid>
      <Grid item xs={11} md={10}>
        <Box sx={{
          background: 'rgba(178, 178, 178, 0.4)',
          borderRadius: 2,
          paddingY: 4,
          paddingX: 2,
        }}>
          <Typography variant="h2" align="center" fontWeight={600}>
            Data Spasial Terintegrasi.
          </Typography>
          <Typography variant="h6" align="center" fontWeight={300}>
            Tiap data tampil dengan visualisasi spasial untuk mempermudah pemantauan.
          </Typography>
          <Box sx={{
            paddingTop: 2
          }}>
            <CardMedia
              src="https://mapstore.pemkotmakassar.com/mapstore/dashboard-embedded.html#/51"
              component={'iframe'}
              sx={{
                height: '86vh'
              }}
            />
          </Box>
          <Typography variant="h6" align="center" fontWeight={300}>
            Sumber Peta : Bappeda,Dinas Tata Ruang, & Diolah pada tahum 2023
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={11} md={10} sx={{
        paddingTop: 3,
        marginBottom: 5,
      }}>
        <Box sx={{
          background: 'rgba(178, 178, 178, 0.4)',
          borderRadius: 2,
        }}>
          <Grid container item xs={12} sx={{
            paddingX: 3
          }}>
            <Grid container item xs={12} md={4} padding={2} alignItems={'center'}>
              <Typography variant="h1">
                Cek data stunting & kemiskinan dengan mudah.
                <Typography variant="h6">
                  Anda dapat melakukan pengecekan data Stunting & Kemiskinan Ekstrim berdasarkan database kami yang termutakhir.
                </Typography>
                <Link to={'login'}>
                <Button variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: 'steelblue'
                }}>
                  Cek Data Anda
                </Button>
                </Link>
              </Typography>
            </Grid>
            <Grid container item xs={12} md={8} alignContent={'center'} justifyContent={'center'}>
              <Box
                margin={10}
                component={'img'}
                alt="table"
                src={TableImg}
                sx={{
                  width: '80%'
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Features;