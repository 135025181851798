import React from "react";
import { useState, useCallback, useEffect } from "react";
import {
    Button, Modal, useTheme, Typography, Grid, Divider, Fade,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination
} from "@mui/material";
import { tokens } from "../../../theme";
import { headerData } from "../../../data/headerCostum";



const columns = [
    {
        id: 'opd_pendamping',
        label: 'OPD Pendamping',
        minWidth: 100
    },
    {
        id: 'createdAt',
        label: 'Waktu Intervensi',
        minWidth: 100,
    },
    {
        id: 'kuantitas',
        label: 'Kuantitas',
        minWidth: 100,
    },
    {
        id: 'unit',
        label: 'Unit',
        minWidth: 100,
    },
    {
        id: 'dokumentasi',
        label: 'Dokumentasi',
        minWidth: 100
    },
];

const CheckHistory = ({ id, execute }) => {
    let rowNumber = 0;
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {setOpenModal(true); getDataIntervensi()};
    const handleCloseModal = () => setOpenModal(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataIntervensi, setDataIntervensi] = useState([]);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getDataIntervensi = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/intervensi/anak/" + id, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setDataIntervensi(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    useEffect(() => {
        if (dataIntervensi.length === 0) {
            getDataIntervensi();
        }
    }, [getDataIntervensi, dataIntervensi.length]
    )

    return (
        <div>
            <Button variant='contained' sx={{
                backgroundColor: colors.blueAccent[500],
                color: colors.grey[100],
                ":hover": {
                    backgroundColor: colors.blueAccent[800]
                },
                margin: '2px'
            }}
                onClick={handleOpenModal}
            >
                <Typography fontSize={9}>
                    Lihat History
                </Typography>
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid xs={11} md={7} lg={5} sx={style}>
                        <Grid item justifyContent={"space-between"} alignItems={"end"}>
                            <Typography variant="h4">
                                History Intervensi : {dataIntervensi.length === 0
                                    ? id
                                    : dataIntervensi[0].data_stunting.nama
                                }
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={handleCloseModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid item m={1}>
                            <Grid item xs={12}>
                                <TableContainer sx={{ maxHeight: '90vh' }}>
                                    <Table size='small' stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    key={'no'}
                                                    align={'center'}
                                                    style={{ minWidth: 5 }}
                                                >
                                                    {'No'}
                                                </TableCell>
                                                <TableCell
                                                    key={'pengendalian'}
                                                    align={'center'}
                                                    style={{ minWidth: 150 }}
                                                >
                                                    {'Jenis Pengendalian'}
                                                </TableCell>
                                                <TableCell
                                                    key={'jenis_intervensi'}
                                                    align={'center'}
                                                    style={{ minWidth: 200 }}
                                                >
                                                    {'Jenis Intervensi'}
                                                </TableCell>
                                                <TableCell
                                                    key={'program'}
                                                    align={'center'}
                                                    style={{ minWidth: 200 }}
                                                >
                                                    {'Aksi / Program'}
                                                </TableCell>
                                                <TableCell
                                                    key={'opd_pen'}
                                                    align={'center'}
                                                    style={{ minWidth: 150 }}
                                                >
                                                    {'OPD Penanggung Jawab'}
                                                </TableCell>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataIntervensi
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    rowNumber += 1;
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell
                                                                align='center'
                                                                key={'no'}>
                                                                {rowNumber + page * rowsPerPage}
                                                            </TableCell>
                                                            <TableCell
                                                                key={'pengendalian'}
                                                                align={'center'}
                                                            >
                                                                {row.master_intervensi.pengendalian}
                                                            </TableCell>
                                                            <TableCell
                                                                key={'jenis_intervensi'}
                                                                align={'center'}
                                                            >
                                                                {row.master_intervensi.jenis_intervensi}
                                                            </TableCell>
                                                            <TableCell
                                                                key={'program'}
                                                                align={'center'}
                                                            >
                                                                {row.master_intervensi.program}
                                                            </TableCell>
                                                            <TableCell
                                                                key={'opd'}
                                                                align={'center'}
                                                            >
                                                                {row.master_intervensi.opd}
                                                            </TableCell>
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number'
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={dataIntervensi.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} m={1} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colors.greenAccent[500]
                                }}
                            >
                                Simpan
                            </Button>
                        </Grid> */}
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
}

export default CheckHistory;