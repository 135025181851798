export const zscoreGiziCalc = (zinput) => {
    let zscore = parseFloat(zinput)
    let result = "";
    if(zscore >= -2 && zscore <= 1 ){
        result = "normal";
    }else if(zscore < -3){
        result = "buruk";
    }else if(zscore > 1 && zscore <= 2){
        result = "resiko_lebih";
    }else if(zscore > 2 && zscore <= 3){
        result = "lebih";
    }else if(zscore > 3){
        result = "obesitas";
    }else if(zscore < (-2) && zscore > (-3)){
        result = "kurang";
    }
    return result;
}