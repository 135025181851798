import React from "react";
import { useState, useEffect, useCallback } from "react";
import { CardMedia, Grid, Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import BoyIcon from '@mui/icons-material/Boy';
import NotesIcon from '@mui/icons-material/Notes';
import { headerData } from "../../../data/headerCostum";

const iconSize = 50;
const textSize = 15;

const MapKemiskinan = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [dataMiskin, setDataMiskin] = useState([]);
    const [jumlahMiskin, setJumlahMiskin] = useState({
        all: 0,
        l: 0,
        p: 0
    });

    const [dataSkalaMiskin, setDataSkalaMiskin] = useState(
        [
            {
                name: 'Ekstrim',
                jumlah: 0,
            },
            {
                name: 'Sedang',
                jumlah: 0,
            },

            {
                name: 'Rendah',
                jumlah: 0,
            },
        ]
    )

    const getDataDashboardMiskin = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/miskin", {
            method: "get",
            headers: headerData
        })
            .then(res => res.json())
            .then(data => setDataMiskin(data))
            .catch(err => console.log(err))
    }, []);

    const calculateDataMiskin = useCallback(() => {
        let jumlahAL = 0;
        let jumlahAP = 0;
        let totalMiskin = dataMiskin.length;
        dataMiskin.map((items) => {
            if (items.jenis_kelamin === 'Perempuan') {
                return jumlahAP += 1;
            } else {
                return jumlahAL += 1;
            }
        })
        setJumlahMiskin({
            all: totalMiskin,
            l: jumlahAL,
            p: jumlahAP
        });
    }, [dataMiskin]);

    const calculateDataSkalaMiskin = useCallback(() => {
        let ekstrim = 0;
        let sedang = 0;
        let rendah = 0;
        dataMiskin.forEach((items) => {
            if (parseFloat(items.penghasilan) <= 18000) {
                ekstrim += 1;
            } else if (parseFloat(items.penghasilan) > 18000 && parseFloat(items.penghasilan) <= 50000) {
                sedang += 1;
            } else if (parseFloat(items.penghasilan) > 50000) {
                rendah += 1
            }
        })

        let dataSkala =
            [
                {
                    name: 'Ekstrim',
                    jumlah: ekstrim,
                },
                {
                    name: 'Sedang',
                    jumlah: sedang,
                },

                {
                    name: 'Rendah',
                    jumlah: rendah,
                },
            ]
        setDataSkalaMiskin(dataSkala)
    }, [setDataSkalaMiskin, dataMiskin])

    useEffect(() => {
        if (dataMiskin.length === 0) {
            getDataDashboardMiskin()
        } else if (jumlahMiskin.all === 0) {
            calculateDataMiskin()
        } else if (dataSkalaMiskin[0].jumlah === 0) {
            calculateDataSkalaMiskin()
        }

    }, [
        getDataDashboardMiskin,
        calculateDataMiskin,
        dataMiskin,
        jumlahMiskin,
    ])


    return (
        <Grid container>
            <Grid container item>
                <Grid container item xs={12} lg={3} md={3}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={6}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        Jumlah Kepala Keluarga
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={6} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {jumlahMiskin.all}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={6}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                    
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        Miskin Skala {dataSkalaMiskin[0].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={6} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataSkalaMiskin[0].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={6}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                    
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        Miskin Skala {dataSkalaMiskin[1].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={6} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataSkalaMiskin[1].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={6}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                    
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        Miskin Skala {dataSkalaMiskin[2].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={6} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataSkalaMiskin[2].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid container item>
                <CardMedia
                    src="https://mapstore.pemkotmakassar.com/mapstore/dashboard-embedded.html#/51"
                    component={'iframe'}
                    sx={{
                        height: '86vh'
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default MapKemiskinan;