import React, { useCallback, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Logo from '../../assets/image/logoheader.png';
import Header from './header';
import Features from './features';
import Contact from './contact';
import { Link } from 'react-router-dom';

const drawerWidth = 240;



function Navigation(props) {


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const myRef = useRef([]);


  const handleClickHeader = () => {
    myRef[0].scrollIntoView({ behavior: 'smooth' });
  }

  const handleClickFeature = () => {
    myRef[1].scrollIntoView({ behavior: 'smooth' });
  };

  const handleClickKontak = () => {
    myRef[2].scrollIntoView({ behavior: 'smooth' });
  }

  const navItems = [
    {
      name: 'Home',
      action: handleClickHeader
    },
    {
      name: 'Fitur',
      action: handleClickFeature
    },
    {
      name: 'Kontak',
      action: handleClickKontak
    }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Box
          component={'img'}
          alt='Karebosi Apps'
          src={Logo}
          sx={{
            height: 50
          }}
        />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => {
          return (<ListItem key={item.name} disablePadding>
            <ListItemButton onClick={item.action} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>)
        })
        }
        <Link to='login'>
          <Button variant='contained' to='login' sx={{
            textTransform: 'capitalize',
            backgroundColor: 'Highlight'
          }}>Masuk Admin</Button>
        </Link>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{
        background: 'rgba(66, 135, 245, 0.7)',
        paddingX: '5vw'
      }} component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box component={'div'}
            sx={{
              flexGrow: 1,
            }}
          >
            <Box
              component={'img'}
              alt='Karebosi Apps'
              src={Logo}
              sx={{
                height: 50,
                display: { xs: 'none', sm: 'block' }
              }}
            />
          </Box>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => {
              return (
                <Button onClick={item.action} key={item.name} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                  {item.name}
                </Button>
              )
            })}
            <Link to='login'>
              <Button variant='contained' to='login' sx={{
                textTransform: 'capitalize',
                backgroundColor: 'Highlight'
              }}>Masuk Admin</Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main">
        <Toolbar />
        <Grid sx={12}>
          <div ref={(ref) => { myRef[0] = ref }}>
            <Header />
          </div>
          <div ref={(ref) => { myRef[1] = ref }}>
            <Features />
          </div>
          <div ref={(ref) => { myRef[2] = ref }}>
            <Contact />
          </div>
        </Grid>
      </Box>
    </Box>
  );
}

export default Navigation;