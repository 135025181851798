import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import FooterLogo from '../../assets/image/footer.png';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';

const Contact = () => {
  return (
    <Grid container justifyContent={'space-between'} sx={{
      paddingX: 25
    }}>
      <Grid container item xs={6} sx={{
        paddingY: 4
      }}>
        <Grid container xs={12} item>
          <Box
            component={'img'}
            alt="Karebosi & Diskominfo"
            src={FooterLogo}
            width={'40%'}
          />
        </Grid>
        <Grid xs={7} item sx={{
          marginTop: 2
        }}>
          We build readymade websites, mobile
          applications, and elaborate online
          business services.
        </Grid>
      </Grid>
      <Grid container item xs={6} alignItems={"center"} sx={{
        paddingLeft: 10
      }}>
        <Box>
          <Typography variant="h6">
            Kontak:
          </Typography>
          <Typography variant="h6" sx={{
            marginTop: 2,
            display: "flex",
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
          >
            <EmailIcon sx={{ marginRight: 1 }} />diskominfo@makassarkota.go.id
          </Typography>
          <Typography sx={{
            marginTop: 2,
            display: "flex",
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <CallIcon sx={{ marginRight: 1 }} />112
          </Typography>
          <Typography variant="body2" sx={{
            marginTop: 2,
            display: "flex",
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            Jl. A. P. Pettarani No.62, Tamamaung, Kec. Panakkukang, Kota Makassar, Sulawesi Selatan 90232
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
        <Grid sx={{
          marginY: 2
        }}>
          <Typography variant="caption">
            Developed By Dinas Komunikasi dan Informatika Kota Makassar
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Contact;