import React from "react";
import { useState, useCallback, useEffect } from "react";
import { CardMedia, Grid, Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import BoyIcon from '@mui/icons-material/Boy';
import NotesIcon  from '@mui/icons-material/Notes';
import { headerData } from "../../../data/headerCostum";
import { zscoreGiziCalc } from "../../../data/zscoreCalc";

const iconSize = 50;
const textSize = 13;

const MapStunting = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [dataAnak, setDataAnak] = useState([]);
    const [jumlahAnak, setJumlahAnak] = useState({
        all: 0,
        l: 0,
        p: 0
    });
    const [dataGizi, setDataGizi] = useState(
        [
            {
                name: 'Gizi Buruk',
                jumlah: 0,
            },
            {
                name: 'Gizi Kurang',
                jumlah: 0,
            },

            {
                name: 'Gizi Baik',
                jumlah: 0,
            },
            {
                name: 'Risiko Gizi Lebih',
                jumlah: 0,
            },
            {
                name: 'Gizi Lebih',
                jumlah: 0,
            },
            {
                name: 'Obesitas',
                jumlah: 0,
            }
        ]
    )
    const getDataDashboardAnak = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/anak", {
            method: "get",
            headers: headerData
        })
            .then(res => res.json())
            .then(data => setDataAnak(data))
            .catch(err => console.log(err))
    }, []);

    const calculateDataAnak = useCallback(() => {
        let jumlahAL = 0;
        let jumlahAP = 0;
        let totalAnak = dataAnak.length;
        dataAnak.map((items) => {
            if (items.jenis_kelamin === 'L') {
                return jumlahAL += 1;
            } else {
                return jumlahAP += 1;
            }
        })
        setJumlahAnak({
            all: totalAnak,
            l: jumlahAL,
            p: jumlahAP
        });
    }, [dataAnak]);

    const calculateDataGizi = useCallback(() => {
        let giziBuruk = 0;
        let giziBaik = 0;
        let giziKurang = 0;
        let resikoGiziLebih = 0;
        let giziLebih = 0;
        let obesitas = 0;
        dataAnak.forEach((items) => {
            if (zscoreGiziCalc(items['ZS_BB_TB']) === 'buruk') {
                giziBuruk += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'kurang') {
                giziKurang += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'normal') {
                giziBaik += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'resiko_lebih') {
                resikoGiziLebih += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'lebih') {
                giziLebih += 1;
            } else if (zscoreGiziCalc(items['ZS_BB_TB']) === 'obesitas') {
                obesitas += 1;
            }
        })
        let dataStatusStunting = [
            {
                name: 'Gizi Buruk',
                jumlah: giziBuruk,
            },
            {
                name: 'Gizi Kurang',
                jumlah: giziKurang,
            },

            {
                name: 'Gizi Baik',
                jumlah: giziBaik,
            },
            {
                name: 'Gizi R. Lebih',
                jumlah: resikoGiziLebih,
            },
            {
                name: 'Gizi Lebih',
                jumlah: giziLebih,
            },
            {
                name: 'Obesitas',
                jumlah: obesitas,
            }
        ]
        setDataGizi(dataStatusStunting)
    }, [dataAnak])

    useEffect(() => {
        if (dataAnak.length === 0) {
            getDataDashboardAnak()
        } else if (jumlahAnak.all === 0) {
            calculateDataAnak()
        } else if (dataGizi[0].jumlah === 0) {
            calculateDataGizi()
        }

    }, [dataAnak,
        getDataDashboardAnak,
        calculateDataAnak,
        calculateDataGizi,
        dataGizi,
        jumlahAnak.all
    ])


    return (
        <Grid container>
            <Grid container item>
                <Grid item xs={6} md={3} lg={2}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={8} lg={6} md={8}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        {dataGizi[0].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={3} lg={6} md={4} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataGizi[0].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={8} lg={6} md={8}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        {dataGizi[1].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={3} lg={6} md={4} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataGizi[1].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={8} lg={6} md={8}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        {dataGizi[2].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={3} lg={6} md={4} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataGizi[2].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={8} lg={6} md={8}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        {dataGizi[3].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={3} lg={6} md={4} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataGizi[3].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={8} lg={6} md={8}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        {dataGizi[4].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={3} lg={6} md={4} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataGizi[4].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Box sx={{
                        border: 1,
                        borderRadius: 3,
                        marginBottom: "10px",
                        marginX: "5px",
                        padding: "20px"
                    }}>
                        <Grid container item xs={12}>
                            <Grid container item xs={8} lg={6} md={8}>
                                <Box>
                                    <BoyIcon sx={{ fontSize: iconSize, color: colors.blueAccent[400] }} />
                                </Box>
                                <Box>
                                    <Typography fontSize={textSize} color={colors.greenAccent[400]}>
                                        {dataGizi[5].name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={3} lg={6} md={4} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="h1" 
                                align="center"
                                >
                                    {dataGizi[5].jumlah}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid xs={12} container item>
                <CardMedia
                    src="https://mapstore.pemkotmakassar.com/mapstore/dashboard-embedded.html#/51"
                    component={'iframe'}
                    sx={{
                        height: '86vh'
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default MapStunting;