import { Box, Grid } from "@mui/material";
import React from "react";
import Navigation from "./navigation";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const Home = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Navigation />
            </Grid>
        </Grid>
    );
}

export default Home;