import React from "react";
import { useState } from "react";
import {
    Button,
    Modal,
    useTheme,
    Typography,
    Grid,
    TextField,
    Divider,
    Fade,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import { tokens } from "../../../theme";
import { headerData } from "../../../data/headerCostum";

function TambahIntervensi({ execute }) {
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [pengendalianValue, setDataPengendalian] = useState(null);
    const [data, setData] = useState({
        intervensi: "",
        opd: "",
        type: "",
    });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    const handleOptionChange = (e) => {
        e.preventDefault();
        setDataPengendalian(e.target.value)
    };

    const pengendalianList = [
        'Spesifik',
        'Sensitif',
        'Pendukung',
        'Terintegrasi'
    ]

    const field = [
        {
            id: 'pengendalian',
            label: 'Pengendalian',
            placeholder: 'Silahkan masukkan Jenis Pengendalian'
        },
        {
            id: 'jenis_intervensi',
            label: 'Jenis Intervensi',
            placeholder: 'Silahkan masukkan Jenis Intervensi'
        },
        {
            id: 'program',
            label: 'Aksi / Program',
            placeholder: 'Silahkan masukkan Aksi / Program'
        },
        {
            id: 'unit',
            label: 'Unit',
            placeholder: 'Jenis Unit'
        },
        {
            id: 'opd',
            label: 'OPD Penanggung Jawab',
            placeholder: 'Silahkan masukkan OPD penanggung jawab'
        },
    ]

    const sendData = () => {
        fetch(process.env.REACT_APP_API_URL + "api/mintervensi", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify(data)
        })
            .then(res => {
                execute()
                handleCloseModal()
                return res.json()
            })
            .catch(err => {
                console.log(err)
            })
    };

    return (
        <div>
            <Button variant='contained' sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ":hover": {
                    backgroundColor: colors.greenAccent[800]
                }
            }}
                onClick={handleOpenModal}
            >
                <Typography>
                    Tambah
                </Typography>
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid container xs={11} md={7} lg={5} sx={style}>
                        <Grid item container justifyContent={"space-between"} alignItems={"end"}>
                            <Typography variant="h4">
                                Tambah Intervensi
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={handleCloseModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid container item m={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel size="small" id="pengendalianValueId">Jenis Pengendalian</InputLabel>
                                    <Select
                                        labelId="pengendalianValueId"
                                        id="demo-simple-select"
                                        value={pengendalianValue}
                                        label="Age"
                                        size="small"
                                        onChange={handleOptionChange}
                                    >
                                        {
                                            pengendalianList.map((items) => {
                                                return (<MenuItem value={items}>{items}</MenuItem>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    field.map((item) => (
                                        <TextField
                                            id={item.id}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                                marginTop: "10px",
                                                ":target-text": {
                                                    borderColor: colors.greenAccent[400]
                                                }
                                            }}
                                            onInput={(e) => setData({ ...data, [item.id]: e.target.value })}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} m={1} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colors.greenAccent[500]
                                }}
                                onClick={sendData}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>

        </div>
    );
}

export default TambahIntervensi;