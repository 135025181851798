import React from "react";
import { useState, useCallback, useEffect } from "react";
import { Button, Modal, useTheme, Typography, Grid, TextField, Divider, Fade } from "@mui/material";
import { tokens } from "../../../theme";
import { headerData } from "../../../data/headerCostum";

const EditdataMiskin = ({ id, execute }) => {
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataMiskin, setdataMiskin] = useState([]);
    const [data, setData] =  useState({});
    
    const getDataStunting = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL+"api/miskin/"+id, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setData(data)
                setdataMiskin(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const sendEditData = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/miskin/"+id, {
            method: 'put',
            headers: headerData,
            body: JSON.stringify(data)
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                execute()
                getDataStunting()
                handleCloseModal()
            })
            .catch(err => {
                console.log(err)
            })
    },[data, id, execute, getDataStunting])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    const field = [
        {
            id: 'kecamatan',
            label: 'Kecamatan',
            placeholder: "Kecamatan",
        },
        {
            id: 'kelurahan',
            label: 'Kelurahan',
            placeholder: "Kelurahan",
        },
        {
            id: 'nik',
            label: 'NIK',
            placeholder: "silahkan masukkan NIK"
        },
        {
            id: 'nama_kk',
            label: 'Nama Kepala Keluarga',
            placeholder: "silahkan masukkan nama kepala keluarga"
        },
        {
            id: 'jenis_kelamin',
            label: 'Jenis Kelamin',
            placeholder: "silahkan masukkan jenis kelamin"
        },
        {
            id: 'pekerjaan',
            label: 'Pekerjaan',
            placeholder: "silahkan masukkan pekerjaan",
        },
        {
            id: 'penghasilan',
            label: 'Penghasilan',
            placeholder: "silahkan masukkan penghasilan",
        },
        {
            id: 'rumah_kep',
            label: 'Kepemilikan Rumah',
            placeholder: "silahkan masukkan kepmilikan rumah",
        },
        {
            id: 'rumah_lantai',
            label: 'Jenis Lantai',
            placeholder: "silahkan masukkan jenis lantai",
        },
        {
            id: 'rumah_jamban',
            label: 'Jenis Jamban',
            placeholder: "silahkan masukkan jenis jamban",
        },
        {
            id: 'sumber_air',
            label: 'Sumber Air',
            placeholder: "silahkan masukkan sumber air",
        },
    ]

    useEffect(() => {
        if(dataMiskin.length === 0) {
            getDataStunting()
        }
    }, [getDataStunting, dataMiskin.length])



    return (
        <div>
            <Button variant='contained' sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ":hover": {
                    backgroundColor: colors.greenAccent[800]
                }
            }}
                onClick={handleOpenModal}
                size="small"
            >
                Edit
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid container xs={11} md={7} lg={5} sx={style}>
                        <Grid item container justifyContent={"space-between"} alignItems={"end"}>
                            <Typography variant="h4">
                                Edit Data Miskin Ekstrim: {id}
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={handleCloseModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid container item m={1}>
                            <Grid item xs={12}>
                                {
                                    field.map((item) => (
                                        <TextField
                                            id={item.id}
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            defaultValue={dataMiskin.length === 0 ? "" : dataMiskin[item.id]}
                                            sx={{
                                                marginTop: "10px",
                                                ":target-text": {
                                                    borderColor: colors.greenAccent[400]
                                                }
                                            }}
                                            onInput={(e) => setData({...data, [item.id]: e.target.value})}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} m={1} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colors.greenAccent[500]
                                }}
                                onClick={() => sendEditData()}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>

        </div>
    );
}

export default EditdataMiskin;