import React from "react";
import { useCallback } from "react";
import { CsvBuilder } from 'filefy';
import { Typography, Button, useTheme } from "@mui/material";
import { tokens } from '../../../theme';

const DownloadTable = ({columns, rows, filename}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const builder = new CsvBuilder(filename)
    const downloadData = useCallback(() => {
        let column = [];
        let list = [];
        columns.forEach(element => {
            column.push(element.label);
        });
        rows.forEach(element => {
            let rowss = []
            {
                columns.forEach((col) => {
                    rowss.push(element[col.id]);

                })
            }
            list.push(rowss)
        });

        builder
            .setDelimeter(',')
            .setColumns(column)
            .addRows(list)
            .exportFile();
    }, [rows]);

    return (
        <Button variant='contained' sx={{
            backgroundColor: colors.greenAccent[600],
            color: colors.grey[100],
            ":hover": {
                backgroundColor: colors.greenAccent[800]
            }
        }}
            onClick={downloadData}
        >
            <Typography>
                Download Data
            </Typography>
        </Button>
    );

}

export default DownloadTable;