import React from 'react';
import { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet, useLocation, Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useMediaQuery } from '@mui/material';
import { tokens, ColorModeContext } from '../../../theme';
import Logo from '../../../assets/image/LogoKarebosi.png';
import EscalatorWarningRoundedIcon from '@mui/icons-material/EscalatorWarningRounded';
import PublicIcon from '@mui/icons-material/Public';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { useAuth } from '../../../auth/auth_provider';


const drawerWidth = 200;
const iconMenuSize = 30;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const DrawerMini = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Item = ({ title, to, icon, selected, setSelected, isOpen }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <ListItem key={title.replace(/\W+/g, '_').toLowerCase()} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'center' : 'center',
                    px: 2.5,
                    color: colors.grey,
                    borderColor: colors.blueAccent[400],
                    borderLeft: (selected === title) ? 3 : 0,
                }}
                component={Link}
                to={to}
                onClick={() => setSelected(title)}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: 'auto',
                                justifyContent: 'center',
                                color: (selected === title) ? colors.primary : colors.grey[400],
                                width: '100%',
                            }}
                        >
                            <Box sx={{
                                backgroundColor: (selected === title) ? colors.blueAccent[400] : colors.primary[400],
                                borderRadius: 1,
                                padding: '5px',
                            }}>
                                {icon}
                            </Box>
                        </ListItemIcon>
                    </Grid>
                    <Grid item xs={12}>
                        <ListItemText primary={title}
                            sx={{
                                opacity: isOpen ? 1 : 0,
                                color: (selected === title) ? colors.primary : colors.grey[400],
                                textAlign: 'center',
                                display: isOpen ? 'visible' : 'none',
                            }}
                            primaryTypographyProps={{ fontSize: '12px' }}
                        />

                    </Grid>
                </Grid>
            </ListItemButton>
        </ListItem>
    );
};

const Menu = (props) => {
    let location = useLocation();
    const { user, logout } = useAuth();
    // Give us meaningful document titles for popping back/forward more than 1 entry
    React.useEffect(() => {
        document.title = location.pathname;
    }, [location]);

    const { window } = props;

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [selected, setSelected] = useState("Dashboard");

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const contain = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <div>
            <DrawerHeader>
                <Grid sx={{ flexGrow: 1 }} component={"div"} container justifyContent='center'>
                    <img src={Logo} alt="karebosi" width={'120px'} />
                </Grid>
            </DrawerHeader>
            <Divider />
            <List>
                <Item
                    title="Dashboard"
                    to="dashboard"
                    icon={<GridViewRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                {user.type === 'user'
                    ? <div></div>
                    : <Item
                        title="Anak Terdaftar"
                        to="anakterdaftar"
                        icon={<EscalatorWarningRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                        selected={selected}
                        setSelected={setSelected}
                        isOpen={open}
                    />}
                {user.type === 'user'
                    ? <div></div>
                    : <Item
                        title="Miskin Ekstrim"
                        to="miskinekstrim"
                        icon={<SupervisorAccountRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                        selected={selected}
                        setSelected={setSelected}
                        isOpen={open}
                    />}
                <Item
                    title="Peta Stunting"
                    to="mapstunting"
                    icon={<PublicIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                <Item
                    title="Peta Kemiskinan"
                    to="mapkemiskinan"
                    icon={<PublicIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                {user.type === 'user'
                    ? <div></div>
                    : <Item
                        title="Program Intervensi Stunting"
                        to="mintervensi"
                        icon={<HowToRegRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                        selected={selected}
                        setSelected={setSelected}
                        isOpen={open}
                    />}
                {user.type === 'user'
                    ? <div></div>
                    : <Item
                        title="Program Intervensi Kemiskinan"
                        to="minterkemiskinan"
                        icon={<HowToRegRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                        selected={selected}
                        setSelected={setSelected}
                        isOpen={open}
                    />}
                <Item
                    title="Intervensi Stunting"
                    to="intervensi"
                    icon={<VolunteerActivismRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                <Item
                    title="Intervensi Kemiskinan"
                    to="interkemiskinan"
                    icon={<VolunteerActivismRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                {user.type === 'user' || user.type === 'admin'
                    ? <div></div>
                    : <Item
                        title="Daftar User"
                        to="daftaruser"
                        icon={<PeopleAltRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                        selected={selected}
                        setSelected={setSelected}
                        isOpen={open}
                    />}
            </List>
            <Divider />
        </div>
    );

    const matches = useMediaQuery(theme.breakpoints.up('sm'));


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} >
                <Toolbar style={{ backgroundColor: colors.grey[800] }}>
                    <Grid container justifyContent={"space-between"} width={"100%"}>
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerClose}
                                edge="start"
                                sx={{
                                    marginRight: 3,
                                    ...(!open && { display: 'none' }),
                                }}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Grid>
                        <Grid item container>
                            <Box display="flex" marginX={1}>
                                <IconButton sx={{ backgroundColor: colors.grey[500] }} onClick={colorMode.toggleColorMode}>
                                    {theme.palette.mode === "dark" ? (
                                        <DarkModeOutlinedIcon />
                                    ) : (
                                        <LightModeOutlinedIcon />
                                    )}
                                </IconButton>
                            </Box>
                            <Box display="flex" marginX={1}>
                                <IconButton sx={{
                                    backgroundColor: colors.redAccent[500],
                                    ":hover": {
                                        backgroundColor: colors.redAccent[700]
                                    }
                                }} onClick={logout}>
                                    <LogoutRoundedIcon sx={{
                                        color: colors.grey[100]
                                    }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                container={contain}
                variant="temporary"
                open={!matches ? open : false}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    overflow: "auto",
                }}
            >
                {/* <Box sx={{
                    height: '100vh'
                }}> */}
                {drawer}
                {/* </Box> */}
            </Drawer>
            <DrawerMini
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
                open={open}>
                {drawer}
            </DrawerMini>
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: "100%", maxHeight: '100vh', overflow: 'auto' }} >
                <DrawerHeader />
                <Grid>
                    <Outlet />
                </Grid>
            </Box>
        </Box>
    );
}

export default Menu;