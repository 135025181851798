import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import {
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import TambahAnak from './tambah';
import EditDataAnak from './edit';
import DeleteAnak from './delete';
import DownloadTable from './download';


import { headerData } from '../../../data/headerCostum';

const columns = [
    {
        id: 'nik',
        label: 'NIK',
        minWidth: 150
    },
    {
        id: 'nama',
        label: 'Nama Anak',
        minWidth: 200,
    },
    {
        id: 'jenis_kelamin',
        label: 'Jenis Kelamin',
        minWidth: 20,
    },
    {
        id: 'kec',
        label: 'Kecamatan',
        minWidth: 150,
    },
    {
        id: 'puskesmas',
        label: 'Puskesmas',
        minWidth: 130,
    },
    {
        id: 'data_kel',
        label: 'Kelurahan',
        minWidth: 130,
    },
    {
        id: 'posyandu',
        label: 'Posyandu',
        minWidth: 200,
    },
    {
        id: 'rt',
        label: 'RT',
        minWidth: 20,
    },
    {
        id: 'rw',
        label: 'RW',
        minWidth: 20,
    },
    {
        id: 'alamat',
        label: 'Alamat',
        minWidth: 250,
    },
    {
        id: 'usia_saat_ukur',
        label: 'Umur',
        minWidth: 170,
    },
    {
        id: 'tanggal_pengukuran',
        label: 'Tanggal Pengukuran Terakhir',
        minWidth: 20,
    },
    {
        id: 'BB_U',
        label: 'Berat Badan / Umur',
        minWidth: 100
    },
    {
        id: 'TB_U',
        label: 'Tinggi Badan / Umur',
        minWidth: 100
    },
    {
        id: 'BB_TB',
        label: 'Berat Badan / Tinggi Badan',
        minWidth: 100
    }
];


export default function Anakterdaftar() {
    const [rows, setRows] = useState([])

    //Page Number
    let rowNumber = 0;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //End of Page Number

    const [copyList, setCopyList] = useState(rows);

    //Filter
    const [kecValue, setkecValue] = useState('');
    const [kecData, setkecData] = useState([]);

    const [puskesmasValue, setpuskemasValue] = useState('');
    const [puskesmasData, setpuskemasData] = useState([]);

    const [kelValue, setkelValue] = useState('');
    const [kelData, setkelData] = useState([]);
    //End of Filter

    const getDataAnak = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/anak", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setRows(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const searchText = (searched) => {
        setCopyList(rows.filter((item) =>
            (isNaN(+searched))
                ? item.nama.toUpperCase().includes(searched.toUpperCase())
                : item.nik.includes(searched)))
    }

    //Page handle
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    //End of page Handle

    //Filter Hanlde
    const getKelData = useCallback((data) => {
        let kelurahan = [];
        data.forEach((items) => {
            if (kelurahan.includes(items.data_kel)) {
                return
            } else {
                kelurahan.push(items.data_kel)
            }
        })
        setkelData(kelurahan)
    }, [setkelData])

    const getPuskesmasData = useCallback((data) => {
        let puskesmas = [];
        data.forEach((items) => {
            if (puskesmas.includes(items.puskesmas)) {
                return
            } else {
                puskesmas.push(items.puskesmas)
            }
        })
        setpuskemasData(puskesmas)
    }, [setpuskemasData])

    const getKecamatanData = useCallback(() => {
        let kec = [];
        rows.forEach((items) => {
            if (kec.includes(items.kec)) {
                return
            } else {
                kec.push(items.kec);
            }
        })
        setkecData(kec);
    }, [rows, setkecData])


    const kecHandle = useCallback((e) => {
        const data = rows.filter((item) =>
            item.kec.toUpperCase().trim().includes(e.toUpperCase().trim())
        )
        setCopyList(data);
        setkecValue(e);
        getPuskesmasData(data);
        setpuskemasValue('');
    }, [setCopyList, setkecValue, rows, getPuskesmasData, setpuskemasValue]);

    const puskesmasHandle = useCallback((e) => {
        const data0 = rows.filter((item) =>
            item.kec.toUpperCase().trim().includes(kecValue.toUpperCase().trim())
        )
        const data = data0.filter((item) =>
            item.puskesmas.toUpperCase().trim().includes(e.toUpperCase().trim())
        )
        setCopyList(data)
        setpuskemasValue(e)
        getKelData(data)
        setkelValue('')
    }, [setCopyList, setpuskemasValue, rows, getKelData, setkelValue, kecValue]);

    const kelurahanHandle = useCallback((e) => {
        const data0 = rows.filter((item) =>
            item.kec.toUpperCase().trim().includes(kecValue.toUpperCase().trim())
        )
        const data1 = data0.filter((item) =>
            item.puskesmas.toUpperCase().trim().includes(puskesmasValue.toUpperCase().trim())
        )
        const data2 = data1.filter((item) =>
            item.data_kel.trim().toUpperCase().match(e.toUpperCase().trim())
        )
        setCopyList(data2)
        setkelValue(e)
    }, [setCopyList, setkelValue, rows, puskesmasValue, kecValue]);

    //End Of Filter handle

    useEffect(() => {
        if (rows.length === 0) {
            getDataAnak()
        } else {
            setCopyList(rows)
            getKecamatanData()
        }
    },
        [getDataAnak, rows, getKecamatanData]
    )

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Grid container xs={12} m={1} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={6} md={5} lg={3}>
                    <TextField
                        id="outlined-textarea"
                        label="Cari"
                        placeholder="NIK atau Nama"
                        sx={{
                            width: '100%'
                        }}
                        size='small'
                        onInput={(e) => searchText(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={6} md={5} lg={3} sx={{
                    paddingRight: 0.5
                }}
                    justifyContent={'end'}
                >
                    <Box sx={{ marginX: 0.5 }}>
                        <DownloadTable columns={columns} rows={copyList} filename={'data_anak_stunting.csv'} />
                    </Box>
                    <Box sx={{ marginX: 0.5 }} >
                        <TambahAnak execute={getDataAnak} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container xs={12} m={1} alignItems={'center'}>
                <Grid item xs={6} md={4} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel size='small' id="kec-select-label">Kecamatan</InputLabel>
                        <Select
                            labelId="kec-select-label"
                            id="kec-select"
                            value={kecValue}
                            label="Kecamatan"
                            onChange={(e) => kecHandle(e.target.value)}
                            size='small'
                        >
                            {
                                kecData.map((items) => {
                                    return <MenuItem value={items}>{items}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {kecValue !== ''
                    ? <Grid item xs={6} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="kec-select-label">Puskesmas</InputLabel>
                            <Select
                                labelId="kec-select-label"
                                id="kec-select"
                                value={puskesmasValue}
                                label="Kecamatan"
                                onChange={(e) => puskesmasHandle(e.target.value)}
                                size='small'
                            >
                                {
                                    puskesmasData.map((items) => {
                                        return <MenuItem value={items}>{items}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    : <></>
                }
                {puskesmasValue !== ''
                    ? <Grid item xs={6} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="kec-select-label">Kelurahan</InputLabel>
                            <Select
                                labelId="kec-select-label"
                                id="kec-select"
                                value={kelValue}
                                label="Kecamatan"
                                onChange={(e) => kelurahanHandle(e.target.value)}
                                size='small'
                            >
                                {
                                    kelData.map((items) => {
                                        return <MenuItem value={items}>{items}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    : <></>
                }
            </Grid>
            <TableContainer sx={{ maxHeight: '90vh' }}>
                <Table size='small' stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'no'}
                                align={'center'}
                                style={{ minWidth: 5 }}
                            >
                                {'No'}
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell
                                key={'aksi'}
                                align={'center'}
                                style={{ minWidth: 180 }}
                            >
                                {'Aksi'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {copyList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                rowNumber += 1;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        <TableCell
                                            align='center'
                                            key={'no'}>
                                            {rowNumber + page * rowsPerPage}
                                        </TableCell>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell
                                            align='center'
                                            key={'aksi'}
                                        >
                                            <Grid container item>
                                                <EditDataAnak id={row.gid} execute={getDataAnak} />
                                                <DeleteAnak id={row.gid} execute={getDataAnak} />
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={copyList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}